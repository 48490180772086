import React from "react"

import Styles from "../styles/trippyTitle.module.scss"
import resume from "../downloads/resume.pdf"

class TrippyTitle extends React.Component {

    state = {
        startingOffset: 0,
        startingRotation: 0,
    }

    constructor(props) {
        super(props);
        this._startingOffset = 0
        this._startingRotation = 0
    }

    componentDidMount(){
    
    }

    componentWillUnmount(){

    }

    render() {
        return(
            <div className={Styles.header}>
                <div className={Styles.trippyTitle}>
                    <h1>Clark Allen</h1>
                    <a href={resume} download="Clark-Allen Resume" className={[Styles.download, Styles.circular].join(' ')} target="blank">
                        <svg viewBox="0 0 47 66" preserveAspectRatio="xMinYMid meet">
                            <path fillRule="evenodd"  fill="rgb(235, 249, 234)" d="M3.910,0.295 L20.327,16.710 C22.613,15.939 25.233,16.456 27.055,18.277 C28.886,20.111 29.400,22.753 28.608,25.047 L38.087,34.524 C40.380,33.733 43.025,34.244 44.856,36.078 C47.416,38.637 47.416,42.783 44.856,45.343 C42.296,47.903 38.149,47.903 35.588,45.343 C33.663,43.417 33.187,40.589 34.162,38.218 L25.322,29.379 L25.321,52.637 C25.945,52.946 26.534,53.359 27.055,53.876 C29.614,56.435 29.614,60.581 27.055,63.143 C24.495,65.702 20.346,65.702 17.789,63.143 C15.229,60.581 15.229,56.435 17.789,53.876 C18.421,53.245 19.153,52.767 19.934,52.447 L19.934,28.972 C19.153,28.653 18.422,28.179 17.788,27.543 C15.850,25.606 15.383,22.761 16.377,20.380 L0.099,4.103 L3.910,0.295 Z"/>
                        </svg>
                    </a>
                    <a href="mailto:clark.allen.clonk@gmail.com" className={Styles.email, Styles.circular} target="blank">
                        <svg viewBox="0 0 50 33" preserveAspectRatio="xMinYMid meet">
                            <path fillRule="evenodd"  fill="rgb(235, 249, 234)" d="M47.558,32.659 L2.363,32.659 C1.323,32.659 0.480,31.823 0.480,30.792 L0.480,7.922 C0.480,6.450 2.119,5.557 3.372,6.345 L23.951,19.297 C24.567,19.684 25.354,19.684 25.970,19.297 L46.549,6.345 C47.803,5.557 49.441,6.450 49.441,7.922 L49.441,30.792 C49.441,31.823 48.598,32.659 47.558,32.659 ZM25.970,15.548 C25.354,15.936 24.567,15.936 23.951,15.548 L5.953,4.221 C4.361,3.220 5.077,0.777 6.962,0.777 L42.959,0.777 C44.844,0.777 45.560,3.220 43.968,4.221 L25.970,15.548 Z"/>
                        </svg>
                    </a>
                </div>
            </div>
            
        )
    }
}

export default TrippyTitle