import React from "react"

import Styles from "../styles/site.module.scss"

import ScrollBar from "../components/scrollbar"
import TrippyTitle from "../components/trippyTitle"
import Helmet from "react-helmet"

class Site extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {}

    componentWillUnmount() {}

    render() {
        
        return (
            <div className={Styles.site}>
                <Helmet>
                    <title>Clark Allen</title>
                    {/* <script src="/Blotter.js"></script> */}
                    {/* <script src="https://unpkg.com/blotterjs-fork@0.1.0/build/blotter.min.js"></script> */}

                </Helmet>
                <TrippyTitle/>
                <ScrollBar />
            </div>
        )
    }
}

export default Site
