import React from "react"
import Styles from "../styles/scrollbar.module.scss"
import Debounce from "../utilities/debounce"
import { isMobile } from "react-device-detect"

class Scrollbar extends React.Component {
    state = {
        scrollbarClasses: [Styles.scrollbar, Styles.hidden],
    }

    constructor(props) {
        super(props)

        this.scrollbar = React.createRef()
        this.handle = React.createRef()
        this.gutter = React.createRef()

        this.scrollingElement = null
        this.scrollbarAdded = false
        this.orientation = null
        this.scrollbarClasses = []

        this.scrollDebounce = Debounce.create(this.removeScrollbar, 1000)

        this.onScroll = this.onScroll.bind(this)
        this.onResize = this.onResize.bind(this)
        this.setHandleSize = this.setHandleSize.bind(this)
        this.addScrollbar = this.addScrollbar.bind(this)
        this.removeScrollbar = this.removeScrollbar.bind(this)
    }

    componentDidMount() {
        window.addEventListener("scroll", this.onScroll, true)
        window.addEventListener("resize", this.onResize)
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll, true)
        window.removeEventListener("resize", this.onResize)
    }

    onResize() {
        if (this.scrollbarAdded) {
            this.setHandleSize(this.orientation)
        }
    }

    onScroll(event) {
        if (event.target !== this.target) {
            this.removeScrollbar()
        }

        this.target = event.target
        if (!this.scrollbarAdded) {
            this.addScrollbar()
        }

        if (this.orientation) {
            if (this.orientation === "horizontal") {
                let x = this.getScrollbarXForContentX(
                    this.scrollingElement.scrollLeft
                )
                this.handle.current.style.transform =
                    "translate3d(" + x + "px, -50%, 0)"
            } else if (this.orientation === "vertical") {
                let y = this.getScrollbarYForContentY(
                    this.scrollingElement.scrollTop
                )
                this.handle.current.style.transform =
                    "translate3d(-50%, " + y + "px, 0)"
            }
        }

        if (isMobile) {
            if (this.target !== document) {
                this.scrollbar.current.classList.remove(Styles.hidden)
            }
        } else {
            this.scrollbar.current.classList.remove(Styles.hidden)
        }
        this.scrollDebounce()
    }

    getScrollbarXForContentX(x) {
        if (!this.doesOverflowX) return 0
        let p = x / this.overflowWidth
        return this.scrollbarMaxWidth * p
    }

    getScrollbarYForContentY(y) {
        if (!this.doesOverflowY) return 0
        let p = y / this.overflowHeight
        return this.scrollbarMaxHeight * p
    }

    addScrollbar() {
        // TODO: this method might need reconsidering. Manually modifying the DOM seems to risk
        // creating runtime errors if React is also attempting to manipulate the DOM
        if (this.target === document) {
            this.scrollingElement = document.documentElement
        } else {
            this.scrollingElement = this.target
        }
        this.scrollbarClasses = [Styles.scrollbar, Styles.hidden]
        if (this.scrollingElement === document.documentElement) {
            this.scrollbarClasses = this.scrollbarClasses.concat([
                Styles.fixed,
                Styles.vertical,
            ])
            this.setState({
                scrollbarClasses: this.scrollbarClasses,
            })
            this.scrollingElement.appendChild(this.scrollbar.current)
            this.orientation = "vertical"
            this.setHandleSize(this.orientation)
        } else {
            let scrollLeft = this.scrollingElement.scrollLeft
            if (scrollLeft > 0) {
                this.scrollbarClasses.push(Styles.horizontal)
                this.setState({
                    scrollbarClasses: this.scrollbarClasses,
                })
                this.scrollingElement.appendChild(this.scrollbar.current)
                this.orientation = "horizontal"
                this.setHandleSize(this.orientation)
            } else {
                this.scrollbarClasses.push(Styles.vertical)
                this.setState({
                    scrollbarClasses: this.scrollbarClasses,
                })

                this.scrollingElement.appendChild(this.scrollbar.current)
                this.orientation = "vertical"
                this.setHandleSize(this.orientation)
            }
        }
        this.scrollbarAdded = true
    }

    removeScrollbar() {
        this.scrollbar.current.classList.add(Styles.hidden)
        this.scrollbarAdded = false
    }

    setHandleSize(orientation) {
        this.gutterWidth = this.gutter.current.offsetWidth
        this.gutterHeight = this.gutter.current.offsetHeight
        this.contentWidth = this.scrollingElement.scrollWidth
        this.contentHeight = this.scrollingElement.scrollHeight

        this.overflowWidth = this.contentWidth - this.gutterWidth
        this.overflowHeight = this.contentHeight - this.gutterHeight
        this.doesOverflowX = this.overflowWidth > 0
        this.doesOverflowY = this.overflowHeight > 0

        this.handle.current.style.removeProperty("width")
        this.handle.current.style.removeProperty("height")

        if (orientation === "horizontal") {
            this.handlePercentSize = this.gutterWidth / this.contentWidth
            this.handleSize = this.gutterWidth * this.handlePercentSize
            this.handle.current.style.width = this.handleSize + "px"
        } else if (orientation === "vertical") {
            this.handlePercentSize = this.gutterHeight / this.contentHeight
            this.handleSize = this.gutterHeight * this.handlePercentSize
            this.handle.current.style.height = this.handleSize + "px"
        }

        this.scrollbarMaxWidth = this.gutterWidth - this.handleSize
        this.scrollbarMaxHeight = this.gutterHeight - this.handleSize
    }

    render() {
        return (
            <div
                className={this.state.scrollbarClasses.join(" ")}
                ref={this.scrollbar}
            >
                <div ref={this.gutter} className={Styles.gutter}>
                    <div className={Styles.handle} ref={this.handle}></div>
                </div>
            </div>
        )
    }
}

export default Scrollbar
